import React from "react";

function Target() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2" data-aos="fade-up">
              We believe that technology should increase rather than decrease
              human agency.
            </h2>
          </div>

          {/* Items */}
          <div className="grid gap-20" data-aos-id-target>
            {/* Item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl"
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-anchor="[data-aos-id-target]"
              >
                <img
                  className="mx-auto md:max-w-none"
                  src={require("../images/brain-illus.png")}
                  width="540"
                  height="520"
                  alt="Features 02"
                />
              </div>

              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6">
                <div className="md:pl-4 lg:pl-12 xl:pl-16">
                  <div
                    className="font-architects-daughter text-xl text-purple-600 mb-2"
                    data-aos="fade-left"
                    data-aos-anchor="[data-aos-id-target]"
                  >
                    It's good and it's good business sense.
                  </div>
                  <div
                    className="mt-6"
                    data-aos="fade-left"
                    data-aos-delay="200"
                    data-aos-anchor="[data-aos-id-target]"
                  >
                    <h4 className="h4 mb-2">
                      <span className="text-purple-600">.</span> Good for People
                    </h4>
                    <p className="text-lg text-gray-400">
                      It's pretty simple-- people generally don't want tech
                      products to wind up manipulating them into doing things
                      they don't want to do.
                    </p>
                    {/* People don't want interactions with technology to wind up
                      manipulating them into doing things they don't want to do.
                      We've all wound up spending hours mindlessly scrolling or
                      reacting to random BS competing for our attention and
                      interupting our every thought, and we don't leave the
                      experience happier or having accomplished our goals.
                      People are waking up to this and realizing that there can
                      be a better way, that technology can instead be empowering
                      instead of stripping our agency. */}
                  </div>
                  <div
                    className="mt-6"
                    data-aos="fade-left"
                    data-aos-delay="400"
                    data-aos-anchor="[data-aos-id-target]"
                  >
                    <h4 className="h4 mb-2">
                      <span className="text-teal-500">.</span> Good for Business
                    </h4>
                    <p className="text-lg text-gray-400">
                      Building products with human agency in mind results in
                      happier, more satisfied customers who stick around, spend
                      more money and refer their friends.
                    </p>
                  </div>
                  <div
                    className="mt-6"
                    data-aos="fade-left"
                    data-aos-delay="600"
                    data-aos-anchor="[data-aos-id-target]"
                  >
                    <h4 className="h4 mb-2">
                      <span className="text-pink-500">.</span> Good for Startups
                    </h4>
                    <p className="text-lg text-gray-400">
                      Many startups exist because big companies develop weird
                      incentive structures that encourage them to not treat
                      their users well, creating an opportunity for someone else
                      to.
                    </p>
                    {/* We think one of the reasons startups exist is because as
                      companies get bigger they often develop weird incentive
                      structures (like posting certain quartery results or rigid
                      bureaucracies) that encourage them to do things that do
                      not ultimately treat their users well. This creates
                      vacuums for new companies to come in and capture market
                      share by not treating users like shit. */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Target;
